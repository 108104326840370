import { ICompanyConfig } from 'interfaces/company.interface'
import { create } from 'zustand'

export interface CompanyState {
  config: ICompanyConfig
  setConfig: (config: ICompanyConfig) => void
}

export const useCompany = create<CompanyState>((set) => ({
  config: {
    locales: [],
  },
  setConfig: (config) => {
    set((state) => ({
      config,
    }))
  },
}))
