import * as es from './locale/es.json'
import * as en from './locale/en.json'
import get from 'lodash.get'
import { LanguageISO6391 } from 'ai-constants'

export const t = (
  word: string,
  variables?: Record<string, any>,
  lang: LanguageISO6391 = 'es',
): string => {
  let translation = get(lang === 'es' ? es : en, word) || word

  if (typeof translation !== 'string') {
    return word
  }

  if (variables) {
    for (const [key, value] of Object.entries(variables)) {
      const variablePlaceholder = `{${key.toLowerCase()}}`
      translation = translation.replace(
        new RegExp(variablePlaceholder, 'gi'),
        String(value),
      )
    }
  }

  return translation
}
