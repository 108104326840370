import getConfig from 'next/config'
const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()
export const API_URL =
  serverRuntimeConfig?.apiUrl || publicRuntimeConfig?.apiUrl

export const BACKOFFICE_API_URL =
  serverRuntimeConfig?.backOfficeApiUrl || publicRuntimeConfig?.backOfficeApiUrl

export const DASHBOARD_PATH = publicRuntimeConfig?.DASHBOARD_PATH
export const LANDING_PATH = publicRuntimeConfig?.LANDING_PATH
export const INSPECTION_APP_BASE_PATH =
  publicRuntimeConfig?.INSPECTION_APP_BASE_PATH

export const MERCADOPAGO_PUBLIC_KEY =
  publicRuntimeConfig?.MERCADOPAGO_PUBLIC_KEY

export const OPEN_API_BASE_URL = publicRuntimeConfig?.OPEN_API_BASE_URL

export const FRONTEND_ENV =
  publicRuntimeConfig?.FRONTEND_ENV || serverRuntimeConfig?.FRONTEND_ENV

export const DOCS_PATH = publicRuntimeConfig?.AUTOINSPECTOR_DOCS_APP_BASE_URL
