import React from 'react'
import Head from 'next/head'

interface Props {
  title: string
}

function Header(props: Props) {
  const title = `Autoinspector | ${props.title}`

  return (
    <Head>
      <title>{title}</title>
    </Head>
  )
}

export default Header
