// appAtoms.tsx
import { IAbstract } from 'interfaces/abstract.interface'
import { atom, PrimitiveAtom, useAtomValue } from 'jotai'
import { ICheckResourcesPageProps } from 'ssr/middlewares/fetchers/check-resources'
import { IPlanAbilitiesPageProps } from 'ssr/middlewares/fetchers/plan-abilities.fetcher'

export type AppPropsCommon = Pick<IAbstract, 'user'> &
  IPlanAbilitiesPageProps &
  ICheckResourcesPageProps

const appPropsAtom = atom({} as any) as PrimitiveAtom<AppPropsCommon>

const authAtom = atom((get) => get(appPropsAtom)?.user)
const permissionsAtom = atom((get) => get(appPropsAtom)?.checkResources)
const planAbilitiesAtom = atom((get) => get(appPropsAtom)?.abilities)

export const usePermissions = () => useAtomValue(permissionsAtom)

export { appPropsAtom, authAtom, permissionsAtom, planAbilitiesAtom }
