'use client'

import { LanguageISO6391 } from 'ai-constants'
import React, { createContext, useContext } from 'react'

export interface LanguageContextProps {
  lang: LanguageISO6391
}

export const LanguageContext = createContext<LanguageContextProps | undefined>(
  undefined,
)

export const useLanguage = () => useContext(LanguageContext)!

export const LanguageProvider = ({
  children,
  ...props
}: {
  children: React.ReactNode
} & LanguageContextProps) => {
  console.log('languageCtx', props)
  return (
    <LanguageContext.Provider value={props}>
      {children}
    </LanguageContext.Provider>
  )
}
