import axios, { AxiosError } from 'axios'
import { useToastCustom } from './useToastCustom'

export const useError = () => {
  const toast = useToastCustom()

  const handleError = (err: Error | unknown | AxiosError) => {
    if (axios.isAxiosError(err)) {
      if (err.response?.status === 429) {
        toast({
          title: 'Limite de inferencias ejecutadas',
          description:
            'Haz ejecutado demasiadas inferences recientemente. Intenta mas tarde',
          status: 'error',
        })

        return
      }

      switch (err.response?.data.code) {
        case 'message_provider_constraints_violated':
          toast({
            title: 'Limitaciones del proveedor de mensajería',
            isClosable: true,
            status: 'error',
            description:
              'El template tiene un formato que viola las limitaciones del proveedor de mensajería. Asegúrate que el titulo no contenga emojis. Contacta con soporte para lograr el formato que deseas.',
          })
          break

        case 'account_not_verified':
          toast({
            title: 'Cuenta no verificada',
            isClosable: true,
            status: 'error',
            description: 'Debes verificar tu cuenta para realizar esta acción.',
          })
          break

        case 'username_already_exist':
          toast({
            isClosable: true,
            status: 'error',
            description: 'Nombre de usuario en uso',
          })
          break

        case 'email_already_exist':
          toast({
            isClosable: true,
            status: 'error',
            description: 'Dirección de correo electrónico en uso',
          })
          break

        case 'reached_coupon_changes_attempts':
          toast({
            title: 'Limite de cupones aplicados',
            description:
              'Haz alcanzado el limite de cupones aplicados este mes. Intenta mas tarde.',
            status: 'error',
          })
          break

        case 'asset_not_found':
          toast({
            title: 'Condicionales sin definir',
            description:
              'El template tiene una condicional haciendo referencia a un recurso que fue eliminado. Elimina o modifica dicha condición para poder guardar.',
            status: 'error',
          })
          break

        case 'coupon_already_applied':
          toast({
            title: 'Cupón ya aplicado',
            description: 'Este cupón ya se encuentra aplicado actualmente',
            status: 'error',
          })
          break

        case 'not_found_coupon':
          toast({
            title: 'Cupón invalido',
            description: 'El cupón de descuento introducido es invalido',
            status: 'error',
          })
          break

        case 'email_already_exist':
          toast({
            title: 'Email ya existente',
            description:
              'El email ingresado ya esta siendo usado por otro usuario',
            status: 'error',
          })
          break

        case 'has_recent_cancelled_subscription':
          toast({
            title: 'Plan cancelado recientemente',
            description:
              'No puedes suscribirte dos veces a un plan en el mismo mes.',
            status: 'error',
          })
          break

        case 'plan_not_included':
          toast({
            title: 'Plan no apto',
            description:
              'Tu plan es de un tipo que no te permite realizar esta acción.',
            status: 'error',
          })
          break

        case 'inspection_dead':
          toast({
            title: 'Inspección expirada',
            description:
              'Esta inspección actualmente se encuentra expirada, por lo que no puedes enviar ninguna notificación.',
            status: 'error',
          })
          break

        case 'cannot_cancel_enterprise_subscription':
          toast({
            title: 'Plan no apto',
            description: 'Tu plan es de un tipo que no se puede cambiar',
            status: 'error',
          })
          break

        case 'reached_base_plan':
          toast({
            title: 'Cantidad Base Totalmente Consumida',
            description:
              'Alcanzaste el limite de plan base y no esta apto para crear una on-demand.',
            status: 'error',
          })
          break

        case 'payment_gateway_not_available':
          toast({
            title: 'Método de pago no disponible',
            description:
              'El método de pago no se encuentra disponible en estos momentos. Intenta mas tarde.',
            status: 'error',
          })
          break

        case 'template_name_duplicated':
          toast({
            title: 'Nombre del template duplicado',
            description:
              'Otro template ya cuenta con el nombre que quieres actualizar',
            status: 'error',
          })
          break

        case 'unpaid_invoice':
          toast({
            title: 'Factura impaga',
            description:
              'Tu empresa posee una factura impaga que se encuentra expirada.',
            status: 'error',
          })
          break

        case 'cannot_create_inspection':
          toast({
            title: 'No puedes crear una inspección.',
            description:
              'Alcanzaste el limite de tu plan o tienes una factura impaga',
            status: 'error',
          })
          break

        case 'reached_limit_plan_changes':
          toast({
            description: 'Llegaste al limite de cambios para el mes corriente.',
            status: 'error',
          })
          break

        case 'subscription_cancelled':
          toast({
            title: 'Suscripción actual cancelada',
            description:
              'Debes esperar a que termine tu suscripción actual si quieres contratar una nueva.',
            status: 'error',
          })
          break

        default:
          toast({
            title: 'Algo ha salido mal',
            description: 'Intente nuevamente o más tarde',
            status: 'error',
          })
          break
      }
    } else {
      toast({
        title: 'Algo ha salido mal',
        description: 'Intente nuevamente o más tarde',
        status: 'error',
      })
    }
  }

  return {
    handleError,
  }
}
