import { Container, Text, Box, Heading, Image, Button } from '@chakra-ui/react'
import NextLink from 'next/link'
import Header from './Header'

export const UnexpectedErrorMessage = () => {
  return (
    <Container centerContent h='100%'>
      <Header title='Algo salió mal' />
      <Box position='relative'>
        <Image
          src='/assets/svg/robot.svg'
          alt='Error robot'
          layout='intrinsic'
          width='600'
          height='600'
        />
        <Heading
          textAlign='center'
          fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}
          position='absolute'
          top='60%'
          right={['35%', '37%', '37%', '35%']}
          color='white'
        >
          500
        </Heading>
      </Box>
      <Box padding={'1rem'} textAlign={'center'}>
        <Heading
          style={{
            fontWeight: 'bold',
          }}
        >
          Algo salió mal
        </Heading>
        <Text my='5' fontSize='lg'>
          El equipo de Autoinspector ya fue notificado del error y lo
          solucionara lo antes posible. Disculpa por las molestias ocasionadas.
        </Text>
      </Box>

      <NextLink href='/'>
        <Button variant={'primary'}>Ir al inicio</Button>
      </NextLink>
    </Container>
  )
}
