import { useLanguage } from './providers/LanguageProvider'
import { t } from './translate'

export const useTranslation = () => {
  const { lang } = useLanguage()

  return (
    word: string,
    variables?: Record<string, any>,
    fallback?: string,
  ): string => {
    return t(word, variables, lang) === word
      ? fallback || word
      : t(word, variables, lang)
  }
}
